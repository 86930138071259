import React, {useEffect} from 'react';
import io from 'socket.io-client';
import logo from './logo.svg';
import './App.css';

function App() {

  useEffect(() => {
    const socket = io('https://socket.ineed.re', {
      transports: ['websocket'], // Utilise uniquement WebSocket
    }); // Mettez ici l'URL de votre backend

    socket.on('connect', () => {
      console.log('Connecté au serveur WebSocket');
    });

    socket.on('test-event-response', (data) => {
      console.log('Réponse à l\'événement "test-event" :', data);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
